
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-services-list-item',

    props: {
      imagePath: {
        required: true,
        type: String,
      },
      service: {
        type: Object,
        required: true,
      },
      to: {
        type: String,
        required: true,
      },
      isDetailsView: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      goToCategory() {
        if (!this.to) return;

        this.$router.push(this.to);
      },
    },
  });
